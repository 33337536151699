.header-wrap-mobile {
	background: $blue-darker;
	display: flex;
	box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
	position: relative;
	z-index: 1;
}

.mobile-menu {
	@include fillall;
	// background: rgba(31, 149, 208, 0.94);
	background: rgba(10, 75, 108, 0.96);
	background: linear-gradient(
		165deg,
		rgba(13, 93, 133, 0.97),
		rgba(10, 87, 126, 0.97)
	);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: auto;
	.nav-links {
		font-size: 3em;
	}
	.nav-item {
		text-align: center;
		padding: 0.4rem 0;
	}
	a {
		color: #fff;
		text-decoration: none !important;
		letter-spacing: 0.04em;
		&.active {
			font-weight: bold;
		}
	}
}

.hwm-logo {
	position: relative;
	background: #fff;
	padding: 7px 15px 9px 30px;
	&:before {
		content: '';
		@include fillall;
		// width: 150px;
		background: #fff;
		transform-origin: top right;
		transform: skew(30deg);
	}
	img {
		width: 65px;
		position: relative;
	}
}

@media (max-width: 1920px) {
	.nav-center a {
		padding: 30px 39px;
	}
}

@media (max-width: 1680px) {
	.page-home .inner-wrap {
		max-width: 920px;
	}
}

@media (max-width: 1500px) {
	.page-home .inner-wrap {
		max-width: 800px;
	}
	.page-home h2 {
		font-size: 50px;
		strong {
			font-size: 90px;
		}
	}
	.nav-center a {
		padding: 30px;
	}
	.navbar {
		padding: 0 50px 0 310px;
	}
	.corner-logo {
		height: 280px;
		img {
			width: 120px;
			bottom: 17px;
			left: 127px;
		}
	}
	.svg-wrap {
		margin: 2rem auto !important;
		svg {
			max-height: 23vh !important;
		}
	}
	.section-heading {
		font-size: 40px;
		strong {
			font-size: 63px;
		}
	}
}

@media (max-width: 1370px) {
	.page-home {
		flex-direction: column;
	}
	.home-next {
		position: relative;
		right: auto;
		display: table;
		margin: 4rem auto 0;
	}
}

@media (max-width: 1259px) {
	.page-why {
		.def-lead .section-header h3 {
			margin-left: 0;
			margin-right: 0;
		}
	}
	// navbar toggled
	.droplets {
		align-self: center;
		margin-left: auto;
	}
}

@media (max-width: 1024px) {
	.page-sections {
		display: block;
	}
	.page {
		padding: 5rem 0;
	}
	.page-home {
		display: block;
		padding: 5rem 0 3rem;
		h2 {
			font-size: 48px;
			// margin-bottom: 1rem;
			strong {
				margin-top: 0.7rem;
				font-size: 86px;
			}
		}
	}
	.home-embed {
		padding: 9px;
		max-width: 650px;
		display: table;
		margin: 0 auto;
	}

	.vid-overlay {
		.vo-icon {
			width: 90px;
			height: 90px;
			border: 6px solid #fff;
		}
		svg {
			left: 29px;
			top: 20px;
		}
		p {
			margin-bottom: 0;
			line-height: 1.5;
			font-size: 20px;
		}
	}
	.default-page {
		background-size: 170% auto;
		padding-bottom: 5rem;
	}
	.def-lead {
		margin: 3rem 0;
		.section-header {
			margin-bottom: 3.5rem;
			h3 {
				margin: 2.5rem 0;
			}
		}
	}
	.grey-black-headline,
	.grey-red-headline {
		font-size: 42px;
		margin-bottom: 1.5rem;
	}
	.content-vid {
		.inner-wrap {
			flex-direction: column;
		}
		.cv-vid {
			max-width: 100%;
			margin-left: 0;
			order: -1;
			width: 100%;
			margin-bottom: 2.5rem;
		}
		.cv-content {
			text-align: left;
		}
	}
	.grey-black-headline {
		br {
			display: none;
		}
	}
	.blue-two-col {
		margin: 5rem 0;
		padding: 5.5rem 0 5rem;
		background-position: left center;
		br {
			display: none;
		}
		h2 {
			margin-bottom: 4rem;
		}
	}
	.graphic-section {
		margin: 3rem 0;
	}
	.jumble-two-col {
		display: block;
	}
	.jtc-img {
		float: right;
		margin: 0 0 1rem 1.2rem;
		max-width: calc(100% - 180px);
	}
	.narrow-jumble {
		h3 {
			font-size: 38px;
		}
	}
	.page-sections:not(.view-three) {
		padding: 5rem 0;
	}
	.page-full-form {
		padding: 3.5rem 0 2rem;
	}
	.section-header {
		margin-bottom: 4.5rem;
	}
	.view-three .ps-main {
		padding: 4rem 6%;
		// .btn {
		// 	background: #696c6d;
		// }
	}
	.next-link {
		// display: none;
		left: 0;
		bottom: 0;
		right: auto;
		top: auto;
		width: 100%;
		text-align: center;
		font-size: 25px;
		letter-spacing: 0.05em;
		padding: 15px 10px 13px;
		line-height: 1;
		// border-radius: 40% 40% 0 0;
		// box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
		// background-color: rgba(149, 152, 153, 0.95) !important;
		// background-color: rgba(10, 75, 108, 0.96) !important;
		// background-color: rgba(65, 158, 218, 0.98) !important;
		&:after {
			border-top: 8.5px solid transparent;
			border-bottom: 8.5px solid transparent;
			border-left: 14px solid #fff;
			margin-left: 9px;
		}
	}
	.view-three-content {
		br {
			display: none;
		}
		h2 {
			font-size: 43px;
			margin-bottom: 1.6rem;
			line-height: 1.1;
		}
		h3 {
			font-size: 24px;
			margin-top: -0.5em;
			margin-bottom: 1rem;
			line-height: 1.2;
		}
		.btn {
			margin: 1rem 0;
		}
	}

	// scroll stuff
	// .app {
	// 	overflow: auto;
	// }
}

@media (max-width: 775px) {
	.page-full-form .view-one .gradient-wrap {
		width: 100%;
	}
	.about-page {
		padding-bottom: 2rem;
	}
}

@media (max-width: 760px) {
	.form-section {
		width: 100%;
		padding-bottom: 0;
		padding: 1.2rem 0;
	}
}

@media (max-width: 600px) {
	.nj-callout {
		padding: 2rem 0;
		margin: 2.2rem 0;
	}
	.next-link {
		font-size: 17px;
		// padding: 14px 10px 10px;
		&:after {
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
			border-left: 10px solid #fff;
			margin-left: 6px;
		}
	}
	.page-full-form .section-header {
		margin-bottom: 2.5rem;
	}
	.inner-wrap {
		width: 100%;
	}
	.hwm-logo {
		padding: 6px 2px 7px 15px;
		margin-right: 20px;
		&:before {
			transform: skew(32deg);
		}
		img {
			width: 50px;
		}
	}
	.page-home {
		padding: 3.8rem 0 2rem;
		h2 {
			font-size: 25px;
			margin-bottom: 2.5rem;
			strong {
				line-height: 1.05;
				font-size: 42px;
				margin-top: 7px;
			}
		}
	}
	.home-embed {
		padding: 5px;
	}
	.vid-overlay {
		.vo-icon {
			width: 65px;
			height: 65px;
			border: 3px solid #fff;
		}
		svg {
			transform: scale(0.7);
			left: 18px;
			top: 9px;
		}
		p {
			font-size: 15px;
			margin-top: 12px;
		}
	}
	.home-next {
		width: 212px;
		margin: 3rem auto 0;
		h3 {
			font-size: 15px;
			margin-top: 17px;
		}
	}
	.section-heading {
		font-size: 24px;
		strong {
			font-size: 33px;
			margin-top: 7px;
		}
	}
	.page-sections:not(.view-three) {
		padding: 3rem 0 2.5rem;
	}
	.section-header {
		margin-bottom: 2.5rem;
	}
	.gradient-wrap {
		padding: 7px;
		// margin: 1.5rem auto;
	}
	.gw-inner {
		padding: 2rem 8%;
		&.second h3 {
			font-size: 28px;
		}
		h3 {
			font-size: 21px;
			line-height: 1.3;
		}
		br {
			display: none;
		}
		p {
			font-size: 14px;
		}
		.answer-exp {
			margin: 1rem 0;
			p {
				font-size: 19px;
			}
		}
		.btn {
			margin-top: 0.7rem;
		}
	}
	.section-footer {
		margin-top: 0.7rem;
	}
	.btn {
		min-width: 220px;
		padding: 11px 29px;
	}
	.button-spinner {
		padding-top: 10px;
		svg {
			height: 26px;
		}
	}
	.section-footer p {
		line-height: 1.3;
	}
	.mobile-menu {
		display: block;
		padding: 40px 0;
		.nav-item {
			padding: 1.5vw 0;
		}
		.nav-links {
			font-size: 7.5vw;
		}
	}
	.section-header p {
		font-size: 16px;
	}
	.view-three {
		.ps-main {
			padding: 3rem 6% 2rem;
		}
		.section-heading {
			font-size: 24px;
			strong {
				font-size: 33px;
				margin-top: 7px;
			}
		}
	}
	.view-three-content {
		font-size: 16px;
		br {
			display: none;
		}
		h2 {
			font-size: 32px;
			margin-bottom: 1rem;
		}
		h3 {
			font-size: 18px;
			margin-top: -0.2em;
		}
		.btn {
			// margin: 1rem 0;
			font-size: 15px;
		}
	}
	.svg-wrap {
		margin: 1.2rem auto !important;
	}
	// .thermometer svg {
	// 	width: 230px;
	// }
	// .svg-wrap svg,
	// .award-svg svg,
	// .tools-svg svg {
	// 	width: 240px;
	// }
	.default-page {
		background-size: 1800px auto;
	}
	.page-full-form .view-one .gw-inner {
		padding: 1rem 6% 3rem;
	}
	.form-section input[type='text'],
	.form-section input[type='email'] {
		width: auto;
		padding: 4px 15px;
		font-size: 17px;
	}
	.default-page {
		font-size: 17px;
	}
	.def-lead {
		margin: 1.3rem 0;
		.section-header {
			margin-bottom: 2.5rem;
			.section-heading {
				font-size: 24px;
				margin-bottom: 2rem;
				strong {
					font-size: 38px;
				}
			}
			p {
				font-size: 17px;
			}
			h3 {
				margin: 1.5rem 0;
			}
			h4 {
				font-size: 26px;
				line-height: 1.2;
			}
		}
	}
	.grey-black-headline,
	.grey-red-headline {
		font-size: 34px;
		// margin-bottom: 1.5rem;
	}
	.graphic-section {
		margin: 1.5rem 0;
	}
	br {
		display: none;
	}
	.blue-two-col {
		padding: 3.5rem 0 3rem;
		margin: 2rem 0;
		background-position: right center;
		h2 {
			font-size: 37px;
			margin-bottom: 2rem;
		}
	}
	.two-col-content {
		display: block;
		> div {
			width: 100%;
		}
	}
	.narrow-jumble {
		h2 {
			margin: 3rem 0 2rem;
		}
		h3 {
			font-size: 31px;
		}
		.btn {
			margin: 3rem 0 0.5rem;
		}
	}
	.video-float {
		margin: 3rem 0;
		.inner-wrap {
			display: flex;
			flex-direction: column;
		}
		.grey-black-headline {
			margin-bottom: 1.4rem;
		}
		.vf-vid {
			order: 2;
			max-width: 100%;
			width: 100%;
			margin: 0 !important;
		}
	}
}

@media (max-width: 350px) {
	.droplets {
		transform: scale(0.8);
	}
}

@media (min-width: 1100px) and (max-height: 1150px) {
	.nav-center a {
		padding: 26px;
	}
	.page-sections {
		display: block;
	}
	.home-embed {
		width: 800px;
		display: table;
		margin-left: auto;
		margin-right: auto;
		padding: 11px;
	}
	.section-header {
		margin-bottom: 3rem;
		margin: 0 auto;
		display: table;
	}
	.section-heading {
		max-width: 900px;
	}
	.view-three-content {
		font-size: 17px;
	}
	.view-three-content h2 {
		font-size: 50px;
	}
	.view-three-content h3 {
		font-size: 28px;
	}
	.view-three-content .btn {
		margin: 1rem 0 1rem;
	}
	.svg-wrap {
		margin: 1.5rem auto !important;
		svg {
			max-height: 29vh !important;
		}
	}
	.default-page {
		font-size: 17px;
		padding: 2rem 0;
	}
	.page-home h2 {
		font-size: 50px;
		margin-bottom: 1em;
		strong {
			font-size: 87px;
		}
	}
	.home-next {
		margin: 3rem auto 0;
	}
}

@media (width: 1440px) and (max-height: 900px) {
	.view-three-content h2 {
		font-size: 43px;
	}
	.view-three-content h3 {
		font-size: 23px;
	}
	.view-three-content {
		font-size: 16px;
	}
	.svg-wrap svg {
		max-height: 23vh !important;
	}
	.view-three-content .btn {
		font-size: 15px;
		padding: 10px;
		min-width: 175px;
	}
	.next-link {
		font-size: 26px;
	}
}
