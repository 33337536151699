[type='radio']:checked,
[type='radio']:not(:checked) {
	position: absolute;
	left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
	position: relative;
	padding-left: 32px;
	cursor: pointer;
	line-height: 26px;
	display: inline-block;
	letter-spacing: 0.04em;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 24px;
	height: 24px;
	border: 1px solid #ddd;
	border-radius: 100%;
	background: #fff;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
	content: '';
	width: 16px;
	height: 16px;
	background: $blue-primary;
	position: absolute;
	top: 4px;
	left: 4px;
	border-radius: 100%;
	transition: all 0.2s ease;
}

@keyframes scalein {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

[type='radio']:not(:checked) + label:after {
	opacity: 0;
	transform: scale(0);
}
[type='radio']:checked + label:after {
	animation: scalein 0.2s both;
}

.no-form {
	display: flex;
	justify-content: center;
	> div {
		margin: 0.85rem;
		display: flex;
		align-items: center;
		label {
			top: 3px;
		}
	}
}
.nf-column {
	text-align: left;
	> div {
		display: table;
		margin: 0.7rem auto 0;
		> div {
			margin: 0.15rem 0;
		}
	}
}
