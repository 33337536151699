* {
	text-decoration-skip-ink: auto;
	-webkit-overflow-scrolling: touch;
}

html {
	overflow: hidden;
	max-width: 100vw;
	height: 100%;
}

body {
	overflow: hidden;
	font-size: 16px;
	font-weight: 400;
	height: 100%;
	font-family: $font-stack;
	color: $font-color;
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	text-rendering: optimizeLegibility;
	&.using-mouse {
		a,
		button,
		input,
		select,
		label,
		textarea {
			outline: 0 !important;
		}
	}
}

hr {
	border-top: 1px solid $color-primary;
}

a {
	color: $color-primary;
	text-decoration: none;
}

p strong {
	font-weight: 600;
}

button,
input[type='submit'] {
	cursor: pointer;
}

button,
input,
select,
optgroup {
	font-family: $font-stack;
}

.btn {
	display: inline-block;
	color: #fff !important;
	text-align: center;
	line-height: 1.2;
	background-color: $blue-primary;
	font-size: 20px;
	font-weight: bold;
	text-transform: uppercase;
	padding: 15px 40px;
	min-width: 280px;
	border-radius: 50px;
	transition: all 0.2s;
	backface-visibility: hidden;
	// will-change: transform;
	text-decoration: none !important;
	border: 0;

	// &:hover,
	// &:focus {
	// 	background-color: darken($color-primary, 4%);
	// 	transform: translateY(-1.5px);
	// }
}

.btn-white {
	background-color: #fff;
	color: $blue-darker !important;
}

#searchform {
	.input-group {
		display: flex;

		input[type='text'] {
			padding: 6px 15px;
		}

		input[type='submit'] {
			border: 0;
			background: $color-primary;
			color: #fff;
			padding: 8px 15px;

			&:hover {
				background: darken($color-primary, 5%);
			}
		}
	}
}

// layout
#panel {
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.2);
	touch-action: pan-y;
	min-height: 100vh;
	> .main {
		flex-grow: 1;
	}
}

.main {
	width: 100%;
	margin: 0 auto;
	padding: 0 6%;
	position: relative;
}

.inner-wrap {
	position: relative;
	// width: 100%;
	max-width: 1300px;
	margin: 0 auto;
}

.main-content-wrap {
	position: relative;
	flex-grow: 1;
	background-color: #fff;
}

.clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}

.page-default-content {
	padding: 3em 0;
}

.sidebar-layout {
	display: flex;
	align-items: flex-start;

	> .sidebar-col {
		order: -1;
		width: 26%;
		margin-right: 5.5%;
		float: left;

		@supports (display: flex) {
			min-width: 280px;
		}
	}

	> article.main-content {
		float: right;
		width: 68.5%;
		flex-grow: 1;
		max-width: 999px;
		margin: 0 auto;
	}
}

////////////////////////// HEADER //////////////////////////

.toggle-nav {
	// display: none;
	font-weight: 300;
	font-size: 1.05em;
	margin: 1em 2% 0.8em 1rem;
	margin-left: auto;
	float: right;
	background: transparent;
	border: 0;

	.hamburger {
		display: inline-block;
		margin-bottom: -3px;

		&.active {
			> div {
				&:nth-of-type(1) {
					transform: translateY(15px) rotate(45deg);
				}
				&:nth-of-type(2) {
					opacity: 0;
				}
				&:nth-of-type(3) {
					transform: translateY(-14.5px) rotate(-45deg);
				}
			}
		}

		> div {
			width: 2.5em;
			height: 3px;
			background-color: #fff;
			margin: 0.7em 0 0;
			transition: all 0.2s;

			&:first-of-type {
				margin: 0;
			}
		}
	}
}

.grid-three {
	margin: 1rem -1% -0.75rem;
	display: flex;
	flex-wrap: wrap;

	.grid-item {
		margin: 0.75rem 1%;
		width: 31.33%;
	}
}

.grid-three-narrow {
	margin: 1rem -2% -0.75rem;
	display: flex;
	flex-wrap: wrap;

	.grid-item {
		margin: 0.75rem 2%;
		width: 29.33%;
	}
}

.grid-two {
	margin: 1rem -2% -0.75rem;
	display: flex;
	flex-wrap: wrap;

	.grid-item {
		margin: 0.75rem 2%;
		width: 46%;
	}
}

.grid-four {
	margin: 1rem -1%;
	display: flex;
	flex-wrap: wrap;

	.grid-item {
		margin: 0.65rem 1%;
		width: 23%;
	}
}

.grid-four-narrow {
	margin: 1rem -2%;
	display: flex;
	flex-wrap: wrap;

	.grid-item {
		margin: 0.65rem 2%;
		width: 21%;
	}
}

.grid-item {
	position: relative;
	background-size: cover;
	background-position: center;
}

////////////////////////// ACCESSIBILITY //////////////////////////
.sr-only {
	position: absolute !important;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}

.sr-only:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; // Above WP toolbar.
}

////////////////////////// GENERAL //////////////////////////
.error404 {
	#panel > .main {
		background-color: $color-primary;
		background-image: linear-gradient(120deg, #92defb 10%, #2855d6 100%);
	}

	article {
		padding: 18vh 0;
		text-align: center;
		font-size: 1.1em;
		color: #fff;

		h1 {
			font-size: 4rem;
			text-transform: uppercase;
			margin: 0 0 2rem;
			color: #fff;
		}

		ul {
			display: table;
			margin: 0 auto;
			text-align: left;
		}
	}
}

#root {
	height: 100%;
}

.app {
	// height: 100vh;
	height: 100%;
	background-color: #eee;
	display: flex;
	flex-direction: column;
}

.main-content {
	position: relative;
	flex-grow: 1;
	overflow: hidden;
	background: rgb(230, 230, 230);
	// background-color: $blue-primary;

	// > div {
	// 	// flex-grow: 1;
	// 	@include fillall;
	// 	// > div {
	// 	// 	height: 100%;
	// 	// }
	// }
}

.page-wrap {
	@include fillall;
	display: flex;
	flex-direction: column;
	will-change: opacity, transform;
}

.header-wrap {
	position: relative;
	z-index: 1;
	a {
		text-decoration: none;
	}
}

.corner-logo {
	top: 0;
	left: 0;
	position: absolute;
	background: #fff;
	width: 999px;
	height: 371px;
	transform: rotate(-41.5deg);
	transform-origin: bottom left;
	box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.24);
	img {
		width: 160px;
		transform: rotate(41.5deg);
		position: absolute;
		bottom: 20px;
		left: 170px;
		backface-visibility: hidden;
	}
}

.navbar {
	background: $blue-darker;
	color: #fff;
	display: flex;
	align-items: center;
	padding: 0 50px 0 420px;

	ul {
		margin: 0;
		padding: 0;
	}
	a {
		color: #fff;
	}
}

.nav-left {
	margin-right: auto;
	font-size: 15px;
	ul {
		display: flex;
	}
	li {
		padding: 0 11px;
		border-right: 1px solid;
		line-height: 13px;
		&:last-child {
			border-right: 0;
		}
	}
	a {
		&[aria-current='page'] {
			font-weight: bold;
		}
	}
}

.nav-center {
	// margin: 0 5%;
	ul {
		display: flex;
	}
	a {
		font-size: 22px;
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 0.09em;
		display: inline-block;
		color: #fff;
		line-height: 1;
		padding: 36px 39px;
		transition: color 0.05s, background-color 0.05s;
		&:hover {
			background-color: #094563;
		}
		&[aria-current='page'] {
			background-color: #062d41;
			color: $blue-primary;
		}
	}
}

.nav-right {
	margin-left: auto;
}

.droplets {
	svg {
		margin: 0 4px;
	}
}

.page-wrap-inner {
	height: 100%;
	overflow: auto;
}

.page {
	// @include fillall;
	padding: 4rem 0;
	min-height: 100%;
	overflow: auto;
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	background-image: -moz-radial-gradient(
		50% 50%,
		circle closest-side,
		rgb(255, 255, 255) 0%,
		rgb(215, 215, 215) 100%
	);
	background-image: -webkit-radial-gradient(
		50% 50%,
		circle closest-side,
		rgb(255, 255, 255) 0%,
		rgb(215, 215, 215) 100%
	);
	background-image: -ms-radial-gradient(
		50% 50%,
		circle closest-side,
		rgb(255, 255, 255) 0%,
		rgb(215, 215, 215) 100%
	);
}

.page-home {
	background-color: #222;
	text-align: center;
	color: #fff;
	.inner-wrap {
		max-width: 1100px;
	}
	h2 {
		font-size: 58px;
		text-transform: uppercase;
		font-weight: 400;
		letter-spacing: 0.03em;
		margin-bottom: 1.3em;
		strong {
			color: $blue-primary;
			display: block;
			font-size: 97px;
			letter-spacing: 0.05em;
			line-height: 0.95;
		}
	}
}

.responsive-embed {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	max-width: 100%;
	// overflow: hidden;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.home-embed {
	background: #fff;
	padding: 15px;
	width: 100%;
	box-shadow: 0px 9px 21px 0px rgba(0, 0, 0, 0.48);
	.responsive-embed {
		background-color: #222;
		background-size: cover;
	}
}

.section-header {
	text-align: center;
	margin-bottom: 6rem;
	p {
		line-height: 1.4;
		font-size: 21px;
		font-style: italic;
		letter-spacing: 0.03em;
		max-width: 880px;
		margin-left: auto;
		margin-right: auto;
	}
}

.section-heading {
	font-size: 48px;
	color: $blue-darker;
	text-align: center;
	text-transform: uppercase;
	line-height: 1.1;
	font-weight: 400;
	letter-spacing: 0.05em;
	strong {
		font-size: 68px;
		color: $blue-primary;
		display: block;
	}
}

// .emojis {
// 	font-size: 170px;
// 	text-align: center;
// 	display: flex;
// 	justify-content: center;
// }

.vid-overlay {
	@include fillall;
	background: rgba(9, 9, 9, 0.35);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	padding: 0;
	appearance: none;
	color: #fff;
	text-align: center;
	overflow: hidden;
	border: 0;
	// &:focus {
	// 	outline: 5px solid $blue-primary;
	// }
	// cursor: pointer;
	.vo-icon {
		width: 110px;
		height: 110px;
		display: flex;
		margin: 0 auto;
		border: 8px solid #fff;
		border-radius: 99%;
	}
	svg {
		position: relative;
		display: inline-block;
		left: 38px;
		top: 27px;
	}
	p {
		font-size: 24px;
		text-transform: uppercase;
		margin-top: 16px;
	}
}

.gradient-wrap {
	padding: 10px;
	background: #fff;
	border-radius: 9px;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
	display: table;
	margin: 2rem auto;
	width: 100%;
	max-width: 690px;
}

.gw-inner {
	background-color: $blue-darker;
	background: linear-gradient(#1495d6, #0a4f72);
	padding: 3.1rem 9% 3.6rem;
	border-radius: inherit;
	color: #fff;
	font-size: 19px;
	text-align: center;
	h3 {
		font-size: 23px;
		text-transform: uppercase;
		font-weight: 400;
		line-height: 1.4;
		letter-spacing: 0.06em;
	}
	&.second {
		h3 {
			text-transform: uppercase;
			font-size: 31px;
			font-weight: bold;
			// margin-top: -0.3rem;
		}
	}
	p {
		font-size: 16px;
		line-height: 1.4;
	}
	.answer-exp {
		margin: 1.5rem 0;
		p {
			font-size: 22px;
		}
	}
	.btn {
		margin-top: 1.5rem;
	}
}

.section-footer {
	text-align: center;
	font-size: 17px;
	margin-top: 4rem;
	color: $blue-darker;
	p {
		line-height: 1.5;
		margin-bottom: 4px;
	}
}

.def-link {
	color: $blue-primary;
	font-weight: bold;
	font-style: italic;
	text-decoration: underline;
	transition: color 0.1s;
}

.page-sections {
	// width: 100%;
	// min-height: 100%;
	align-self: stretch;
	display: flex;
	flex-direction: column;
	@include fillall;
	overflow: auto;
	padding: 4rem 0 3rem;
	scroll-behavior: smooth;
}

.ps-main {
	flex-grow: 1;
	display: flex;
	align-items: center;
}

@keyframes fadein {
	0% {
		opacity: 0.01;
	}
	100% {
		opacity: 0.99;
	}
}

@keyframes fadeinsmoke {
	0% {
		opacity: 0.01;
	}
	100% {
		opacity: 0.18;
	}
}

.page-why,
.page-what,
.page-when {
	canvas {
		backface-visibility: hidden;
		// will-change: transform;
		@include fillall;
		width: 100% !important;
		height: 100% !important;
		opacity: 0.01;
		animation: fadeinsmoke 2.6s forwards;
		// animation-delay: 0.4s;
	}
	.view-three {
		.ps-main {
			position: relative;
			overflow: hidden;
			.inner-wrap {
				z-index: 1;
			}
		}
	}
	> .main {
		position: relative;
		z-index: 1;
	}
}

.page-why {
	.def-lead .section-header h3 {
		margin-left: -1.5rem;
		margin-right: -1.5rem;
	}
}

.svg-wrap {
	display: table;
	margin: 5vh auto;
	svg {
		height: 350px;
		max-width: 100%;
		max-height: 32vh !important;
	}
}

.view-two {
	z-index: 1;
}

.view-three {
	z-index: 1;
	padding: 0;
	display: block;
	.ps-main {
		background-repeat: no-repeat;
		background-position: top center;
		padding: 3rem 6%;
		min-height: 100%;
	}
	.section-header {
		margin-bottom: 0;
	}
	.section-heading {
		font-size: 27px;
		strong {
			font-size: 47px;
		}
	}
	video {
		opacity: 0.5;
	}
}

.view-three-content {
	text-align: center;
	font-size: 18px;
	h2 {
		color: $red-primary;
		font-size: 72px;
		margin-bottom: 0.45em;
		line-height: 1.2;
	}
	h3 {
		font-size: 35px;
		text-transform: uppercase;
		color: $red-primary;
		margin-top: -0.8em;
		line-height: 1.4;
		margin-bottom: 1em;
	}
	p {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
	.btn {
		margin: 1.8rem 0 1rem;
		font-size: 17px;
		padding: 12px 37px;
		min-width: 199px;
		background-color: #696c6d;
	}
}

.learn-more-why {
	background: #fff;
	padding: 9rem 0;
	min-height: 100%;
}

.default-page {
	font-size: 20px;
	background: #fff;
	min-height: 100%;
	padding: 3rem 0;
	background-size: 100% auto;
	background-position: top;
	background-repeat: no-repeat;
	ul {
		padding-left: 20px;
	}
	li {
		line-height: 1.4;
		margin-bottom: 15px;
	}
	p > a {
		transition: color 0.07s;
		&:hover {
			text-decoration: none;
		}
	}
}

.def-lead {
	margin: 5rem 0;
	.inner-wrap {
		max-width: 885px;
	}
	.section-header {
		.section-heading {
			font-size: 27px;
			margin-bottom: 2rem;
			strong {
				font-size: 47px;
			}
		}
		p {
			font-size: 20px;
			font-style: initial;
			line-height: 1.8;
		}
		h3 {
			margin: 3.7rem 0;
		}
		h4 {
			color: $blue-primary;
			font-size: 33px;
		}
	}
}

.grey-black-headline,
.grey-red-headline {
	font-size: 50px;
	font-weight: 400;
	line-height: 1.2;
	color: #878787;
	margin-bottom: 2rem;
	strong {
		color: #434343;
	}
}

.grey-red-headline {
	strong {
		color: $red-primary;
	}
}

.content-vid {
	.inner-wrap {
		display: flex;
		align-items: center;
		max-width: 1170px;
	}
	.cv-content {
		text-align: justify;
	}
	.cv-vid {
		background-color: #333;
		width: 650px;
		max-width: 50%;
		margin-left: 7%;
		flex-shrink: 0;
	}
}

.graphic-section {
	margin: 3.5rem 0;
	text-align: center;
	.inner-wrap {
		max-width: 1170px;
	}
	figure {
		margin: 3rem 0;
	}
	img {
		margin: 0 auto;
	}
}

.blue-two-col {
	margin: 7rem 0;
	padding: 6.5rem 0 7rem;
	background: $blue-primary;
	color: #fff;
	background-size: cover;
	background-position: center;
	h2 {
		font-size: 50px;
		font-weight: 400;
		text-align: center;
		margin-bottom: 5rem;
	}
	.inner-wrap {
		max-width: 1020px;
	}
	a {
		color: #fff;
		text-decoration: underline;
		font-weight: bold;
	}
}

.two-col-content {
	display: flex;
	justify-content: space-around;
	> div {
		width: 45%;
	}
	p {
		line-height: 1.55;
	}
}

.ta-center {
	text-align: center;
}

.narrow-jumble {
	.inner-wrap {
		max-width: 880px;
	}
	h2,
	h3 {
		text-align: center;
		margin: 2.8rem 0;
	}
	h3 {
		color: $blue-primary;
		font-size: 40px;
		font-weight: 400;
		line-height: 1.3;
	}
	.btn {
		display: table;
		margin: 4rem auto;
	}
}

.page-when {
	.narrow-jumble {
		h2,
		h3 {
			text-align: left;
		}
	}
	.video-float {
		// ul {
		// 	list-style: none;
		// 	padding-left: 0;
		// }
		// li {
		// 	position: relative;
		// 	padding-left: 17px;
		// 	display: table;
		// 	&:before {
		// 		content: '\2022';
		// 		position: absolute;
		// 		left: 0;
		// 	}
		// }
	}
	.vf-left .vf-vid {
		margin: 9px 3rem 1rem 0;
	}
}

.jumble-two-col {
	display: flex;
	align-items: flex-start;
}

.jtc-img {
	margin-right: 8%;
}

.next-link {
	background-color: $blue-darker;
	color: #fff !important;
	text-decoration: none !important;
	padding: 0.7rem 2.1rem;
	position: absolute;
	top: 50%;
	top: calc(50% - 35px);
	right: 0;
	z-index: 1;
	font-size: 32px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0.02em;
	transition: all 0.1s;
	opacity: 0.01;
	animation: fadein 0.7s forwards;
	animation-delay: 0.6s;
	&:hover,
	&:focus {
		background-color: lighten($blue-darker, 4%);
	}
	&:after {
		content: '';
		width: 0;
		height: 0;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-left: 20px solid #fff;
		display: inline-block;
		vertical-align: middle;
		margin-left: 10px;
		top: -2px;
		position: relative;
	}
}

.background-video {
	@include fillall;
}

@keyframes shake-horizontal {
	0%,
	100% {
		transform: translateX(0);
	}
	10%,
	30%,
	50%,
	70% {
		transform: translateX(-4px);
	}
	20%,
	40%,
	60% {
		transform: translateX(4px);
	}
	80% {
		transform: translateX(4px);
	}
	90% {
		transform: translateX(-4px);
	}
}

.home-next {
	position: absolute;
	right: 18px;
	width: 250px;
	// margin-top: 1rem;
	// cursor: pointer;
	display: block;
	text-decoration: none !important;
	color: #fff !important;
	svg {
		position: relative;
		display: block;
		margin: 0 auto;
		animation: shake-horizontal 3.5s cubic-bezier(0.455, 0.03, 0.515, 0.955)
			infinite both;
	}
	h3 {
		font-size: 19px;
		margin-top: 12px;
		line-height: 1.4;
		text-transform: uppercase;
		letter-spacing: 0.04em;
	}
}

.page-who {
	.view-three-content {
		h3 {
			margin-top: -0.5em;
		}
		p {
			max-width: 835px;
		}
	}
}

.page-full-form {
	.section-header {
		margin-bottom: 4rem;
		p {
			margin-top: 1.5rem;
		}
	}
	.view-one {
		display: block;
		position: relative;
		padding: 0 !important;
		overflow: visible;
		.gradient-wrap {
			width: 930px;
		}
		.gw-inner {
			display: flex;
			flex-wrap: wrap;
			padding: 1rem 3% 4rem;
		}
	}
	.form-button {
		width: 100%;
		margin: 1rem 0 0;
	}
}

.form-section {
	padding: 2.5rem 3% 2rem;
	position: relative;
	width: 50%;
	flex-grow: 1;
	&:first-child,
	&:nth-child(2) {
		padding-bottom: 0;
	}
	input[type='text'],
	input[type='email'] {
		margin: 9px 0 1rem;
		background: transparent;
		color: #fff;
		border: 2px solid #fff;
		border-radius: 20px;
		padding: 5px 15px;
		width: 280px;
	}
	// &:before {
	// 	content: '';
	// 	position: absolute;
	// 	left: 15%;
	// 	width: 70%;
	// 	top: 1px;
	// 	height: 1px;
	// 	background: rgba(255, 255, 255, 0.7);
	// }
	// &:first-child {
	// 	padding-top: 0;
	// 	&:before {
	// 		display: none;
	// 	}
	// }
}

// .about-long-content {

// }

.video-float {
	margin: 3.5rem 0;
	&:after {
		content: '';
		display: table;
		clear: both;
	}
	.inner-wrap {
		max-width: 1170px;
	}
	.grey-black-headline {
		// text-align: center;
		margin-bottom: 4rem;
	}
}

.vf-content {
	h3 {
		color: $blue-primary;
		font-size: 1.1em;
		margin: 1.5rem 0;
		line-height: 1.3;
	}
}

.vf-vid {
	float: right;
	margin: 9px 0 1rem 2rem;
	width: 49%;
	max-width: calc(100% - 180px);
}

.vf-img {
	width: 42%;
	margin: 9px 0 1rem 5%;
}

.vf-left {
	.vf-vid {
		float: left;
		margin: 9px 2rem 1rem 0;
	}
	.vf-img {
		margin: 9px 5% 1rem 0;
	}
}

.btn-submit {
	position: relative;
	overflow: hidden;
}

@keyframes rotatemiddle {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}

.button-spinner {
	@include fillall;
	padding-top: 11px;
	background-color: #fff;

	svg {
		height: 30px;
		animation: rotatemiddle 1.3s linear infinite;
	}
}

sup {
	font-weight: bold;
	// margin-left: 4px;
	font-size: 12px;
	// vertical-align: top;
}

.p-cite {
	position: relative;
	.the-citations {
		position: absolute;
		// right: -23px;
		top: -2px;
		line-height: 1;
		margin-left: 2px;
	}
	sup {
		margin-left: 1px;
	}
}

h3,
h4 {
	.p-cite {
		.the-citations {
			top: -7px;
		}
	}
}

.citations {
	background-color: #f1f1f1;
	padding: 3.5rem 0;
	font-size: 16px;
	margin-bottom: -3rem;
	margin-top: 5rem;
	.inner-wrap {
		max-width: 1020px;
	}
	ol {
		margin: 0;
		padding-left: 15px;
		margin-top: 17px;
	}
	li {
		margin-bottom: 9px;
	}
}

.left-justify {
	p {
		text-align: left !important;
	}
}

.about-page {
	.btn {
		margin: 1.5rem 0 3rem;
	}
	.section-header {
		text-align: left;
		ul {
			text-align: left;
			margin: 1.5rem 0;
			// font-weight: bold;
			// max-width: 800px;
		}
	}
}

.nj-callout {
	padding: 2.7rem 0;
	margin: 3.3rem 0;
	position: relative;
	h3 {
		margin: 0;
	}
	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		width: 100%;
		left: 0;
		height: 2px;
		background: $blue-primary;
		background: linear-gradient(
			to left,
			rgba(31, 148, 207, 0),
			rgba(31, 148, 207, 0.5),
			rgba(31, 148, 207, 0.5),
			rgba(31, 148, 207, 0)
		);
	}
	&:after {
		top: auto;
		bottom: 0;
	}
}
