*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex='-1']:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0.5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0; }

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

/* This project is under construction. It's not ready for release yet. As of Feb 11, 2019. */
/* Immediately jump any animation to the end point if the user has set their device to "prefers reduced motion". */
/* This could create bad, unintended consequences. Remove as needed, and write your own appropriate code for prefers-reduced-motion. */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important; } }

/* Switch to using rem units for typography. Fix line-height on headlines. */
h1 {
  font-size: 2rem;
  /* Make all H1 large, remove nested-shrinking sizes. */
  margin: 0.67em 0; }

h2 {
  font-size: 1.5rem; }

h3 {
  font-size: 1.17rem; }

h4 {
  font-size: 1rem; }

h5 {
  font-size: 0.83rem; }

h6 {
  font-size: 0.67rem; }

h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1; }

/* Improve readability */
p {
  line-height: 1.85; }

pre {
  white-space: pre-wrap;
  /* Overflow by default is bad. */ }

/* Are browsers now consistent with margin & padding on lists?
  See: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Lists_and_Counters/Consistent_list_indentation  */
nav ul {
  list-style: none; }

img {
  border-style: none;
  /* Remove the border on images inside links in IE 10 and earlier. */
  vertical-align: bottom;
  /* Fix problem with images having a tiny gap for a descender under them. */
  display: block;
  /* Switch display mode to block, since that's what we usually want for images. */
  max-width: 100%;
  /* Make images flexible by default. */
  height: auto;
  /* Ensure images maintain their aspect ratio when max-width comes into play. */ }

/* In English, when styling the <q> element, use curly quotes instead of straight quotes. */
/* Code for this is now in the quotes.css file */
/* Support upcoming properties that haven't been broadly implemented yet,
   but for which the initial value and legacy behavior is not be the best behavior.
*/
/* Consistent line spacing, which does not unnecessarily grow to accommodate things that would fit anyway */
:root {
  line-sizing: normal; }

/* Improve spacing of punctuation marks and at script changes in CJK languages */
:root {
  text-spacing: trim-start allow-end trim-adjacent ideograph-alpha ideograph-numeric; }

[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px; }

[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  line-height: 26px;
  display: inline-block;
  letter-spacing: 0.04em; }

[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff; }

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 16px;
  height: 16px;
  background: #1f93cf;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease; }

@keyframes scalein {
  0% {
    opacity: 0;
    transform: scale(0); }
  100% {
    opacity: 1;
    transform: scale(1); } }

[type='radio']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

[type='radio']:checked + label:after {
  animation: scalein 0.2s both; }

.no-form {
  display: flex;
  justify-content: center; }
  .no-form > div {
    margin: 0.85rem;
    display: flex;
    align-items: center; }
    .no-form > div label {
      top: 3px; }

.nf-column {
  text-align: left; }
  .nf-column > div {
    display: table;
    margin: 0.7rem auto 0; }
    .nf-column > div > div {
      margin: 0.15rem 0; }

* {
  text-decoration-skip-ink: auto;
  -webkit-overflow-scrolling: touch; }

html {
  overflow: hidden;
  max-width: 100vw;
  height: 100%; }

body {
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
  height: 100%;
  font-family: "PT Sans", sans-serif;
  color: #434343;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility; }
  body.using-mouse a,
  body.using-mouse button,
  body.using-mouse input,
  body.using-mouse select,
  body.using-mouse label,
  body.using-mouse textarea {
    outline: 0 !important; }

hr {
  border-top: 1px solid #1f93cf; }

a {
  color: #1f93cf;
  text-decoration: none; }

p strong {
  font-weight: 600; }

button,
input[type='submit'] {
  cursor: pointer; }

button,
input,
select,
optgroup {
  font-family: "PT Sans", sans-serif; }

.btn {
  display: inline-block;
  color: #fff !important;
  text-align: center;
  line-height: 1.2;
  background-color: #1f93cf;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px 40px;
  min-width: 280px;
  border-radius: 50px;
  transition: all 0.2s;
  backface-visibility: hidden;
  text-decoration: none !important;
  border: 0; }

.btn-white {
  background-color: #fff;
  color: #0a4b6c !important; }

#searchform .input-group {
  display: flex; }
  #searchform .input-group input[type='text'] {
    padding: 6px 15px; }
  #searchform .input-group input[type='submit'] {
    border: 0;
    background: #1f93cf;
    color: #fff;
    padding: 8px 15px; }
    #searchform .input-group input[type='submit']:hover {
      background: #1c83b9; }

#panel {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.2);
  touch-action: pan-y;
  min-height: 100vh; }
  #panel > .main {
    flex-grow: 1; }

.main {
  width: 100%;
  margin: 0 auto;
  padding: 0 6%;
  position: relative; }

.inner-wrap {
  position: relative;
  max-width: 1300px;
  margin: 0 auto; }

.main-content-wrap {
  position: relative;
  flex-grow: 1;
  background-color: #fff; }

.clearfix:after {
  content: '';
  display: block;
  clear: both; }

.page-default-content {
  padding: 3em 0; }

.sidebar-layout {
  display: flex;
  align-items: flex-start; }
  .sidebar-layout > .sidebar-col {
    order: -1;
    width: 26%;
    margin-right: 5.5%;
    float: left; }
    @supports (display: flex) {
      .sidebar-layout > .sidebar-col {
        min-width: 280px; } }
  .sidebar-layout > article.main-content {
    float: right;
    width: 68.5%;
    flex-grow: 1;
    max-width: 999px;
    margin: 0 auto; }

.toggle-nav {
  font-weight: 300;
  font-size: 1.05em;
  margin: 1em 2% 0.8em 1rem;
  margin-left: auto;
  float: right;
  background: transparent;
  border: 0; }
  .toggle-nav .hamburger {
    display: inline-block;
    margin-bottom: -3px; }
    .toggle-nav .hamburger.active > div:nth-of-type(1) {
      transform: translateY(15px) rotate(45deg); }
    .toggle-nav .hamburger.active > div:nth-of-type(2) {
      opacity: 0; }
    .toggle-nav .hamburger.active > div:nth-of-type(3) {
      transform: translateY(-14.5px) rotate(-45deg); }
    .toggle-nav .hamburger > div {
      width: 2.5em;
      height: 3px;
      background-color: #fff;
      margin: 0.7em 0 0;
      transition: all 0.2s; }
      .toggle-nav .hamburger > div:first-of-type {
        margin: 0; }

.grid-three {
  margin: 1rem -1% -0.75rem;
  display: flex;
  flex-wrap: wrap; }
  .grid-three .grid-item {
    margin: 0.75rem 1%;
    width: 31.33%; }

.grid-three-narrow {
  margin: 1rem -2% -0.75rem;
  display: flex;
  flex-wrap: wrap; }
  .grid-three-narrow .grid-item {
    margin: 0.75rem 2%;
    width: 29.33%; }

.grid-two {
  margin: 1rem -2% -0.75rem;
  display: flex;
  flex-wrap: wrap; }
  .grid-two .grid-item {
    margin: 0.75rem 2%;
    width: 46%; }

.grid-four {
  margin: 1rem -1%;
  display: flex;
  flex-wrap: wrap; }
  .grid-four .grid-item {
    margin: 0.65rem 1%;
    width: 23%; }

.grid-four-narrow {
  margin: 1rem -2%;
  display: flex;
  flex-wrap: wrap; }
  .grid-four-narrow .grid-item {
    margin: 0.65rem 2%;
    width: 21%; }

.grid-item {
  position: relative;
  background-size: cover;
  background-position: center; }

.sr-only {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-only:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; }

.error404 #panel > .main {
  background-color: #1f93cf;
  background-image: linear-gradient(120deg, #92defb 10%, #2855d6 100%); }

.error404 article {
  padding: 18vh 0;
  text-align: center;
  font-size: 1.1em;
  color: #fff; }
  .error404 article h1 {
    font-size: 4rem;
    text-transform: uppercase;
    margin: 0 0 2rem;
    color: #fff; }
  .error404 article ul {
    display: table;
    margin: 0 auto;
    text-align: left; }

#root {
  height: 100%; }

.app {
  height: 100%;
  background-color: #eee;
  display: flex;
  flex-direction: column; }

.main-content {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  background: #e6e6e6; }

.page-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  will-change: opacity, transform; }

.header-wrap {
  position: relative;
  z-index: 1; }
  .header-wrap a {
    text-decoration: none; }

.corner-logo {
  top: 0;
  left: 0;
  position: absolute;
  background: #fff;
  width: 999px;
  height: 371px;
  transform: rotate(-41.5deg);
  transform-origin: bottom left;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.24); }
  .corner-logo img {
    width: 160px;
    transform: rotate(41.5deg);
    position: absolute;
    bottom: 20px;
    left: 170px;
    backface-visibility: hidden; }

.navbar {
  background: #0a4b6c;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 50px 0 420px; }
  .navbar ul {
    margin: 0;
    padding: 0; }
  .navbar a {
    color: #fff; }

.nav-left {
  margin-right: auto;
  font-size: 15px; }
  .nav-left ul {
    display: flex; }
  .nav-left li {
    padding: 0 11px;
    border-right: 1px solid;
    line-height: 13px; }
    .nav-left li:last-child {
      border-right: 0; }
  .nav-left a[aria-current='page'] {
    font-weight: bold; }

.nav-center ul {
  display: flex; }

.nav-center a {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.09em;
  display: inline-block;
  color: #fff;
  line-height: 1;
  padding: 36px 39px;
  transition: color 0.05s, background-color 0.05s; }
  .nav-center a:hover {
    background-color: #094563; }
  .nav-center a[aria-current='page'] {
    background-color: #062d41;
    color: #1f93cf; }

.nav-right {
  margin-left: auto; }

.droplets svg {
  margin: 0 4px; }

.page-wrap-inner {
  height: 100%;
  overflow: auto; }

.page {
  padding: 4rem 0;
  min-height: 100%;
  overflow: auto;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  background-image: -moz-radial-gradient(50% 50%, circle closest-side, white 0%, #d7d7d7 100%);
  background-image: -webkit-radial-gradient(50% 50%, circle closest-side, white 0%, #d7d7d7 100%);
  background-image: -ms-radial-gradient(50% 50%, circle closest-side, white 0%, #d7d7d7 100%); }

.page-home {
  background-color: #222;
  text-align: center;
  color: #fff; }
  .page-home .inner-wrap {
    max-width: 1100px; }
  .page-home h2 {
    font-size: 58px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.03em;
    margin-bottom: 1.3em; }
    .page-home h2 strong {
      color: #1f93cf;
      display: block;
      font-size: 97px;
      letter-spacing: 0.05em;
      line-height: 0.95; }

.responsive-embed {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  max-width: 100%; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.home-embed {
  background: #fff;
  padding: 15px;
  width: 100%;
  box-shadow: 0px 9px 21px 0px rgba(0, 0, 0, 0.48); }
  .home-embed .responsive-embed {
    background-color: #222;
    background-size: cover; }

.section-header {
  text-align: center;
  margin-bottom: 6rem; }
  .section-header p {
    line-height: 1.4;
    font-size: 21px;
    font-style: italic;
    letter-spacing: 0.03em;
    max-width: 880px;
    margin-left: auto;
    margin-right: auto; }

.section-heading {
  font-size: 48px;
  color: #0a4b6c;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.1;
  font-weight: 400;
  letter-spacing: 0.05em; }
  .section-heading strong {
    font-size: 68px;
    color: #1f93cf;
    display: block; }

.vid-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(9, 9, 9, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 0;
  appearance: none;
  color: #fff;
  text-align: center;
  overflow: hidden;
  border: 0; }
  .vid-overlay .vo-icon {
    width: 110px;
    height: 110px;
    display: flex;
    margin: 0 auto;
    border: 8px solid #fff;
    border-radius: 99%; }
  .vid-overlay svg {
    position: relative;
    display: inline-block;
    left: 38px;
    top: 27px; }
  .vid-overlay p {
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 16px; }

.gradient-wrap {
  padding: 10px;
  background: #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  display: table;
  margin: 2rem auto;
  width: 100%;
  max-width: 690px; }

.gw-inner {
  background-color: #0a4b6c;
  background: linear-gradient(#1495d6, #0a4f72);
  padding: 3.1rem 9% 3.6rem;
  border-radius: inherit;
  color: #fff;
  font-size: 19px;
  text-align: center; }
  .gw-inner h3 {
    font-size: 23px;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.06em; }
  .gw-inner.second h3 {
    text-transform: uppercase;
    font-size: 31px;
    font-weight: bold; }
  .gw-inner p {
    font-size: 16px;
    line-height: 1.4; }
  .gw-inner .answer-exp {
    margin: 1.5rem 0; }
    .gw-inner .answer-exp p {
      font-size: 22px; }
  .gw-inner .btn {
    margin-top: 1.5rem; }

.section-footer {
  text-align: center;
  font-size: 17px;
  margin-top: 4rem;
  color: #0a4b6c; }
  .section-footer p {
    line-height: 1.5;
    margin-bottom: 4px; }

.def-link {
  color: #1f93cf;
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
  transition: color 0.1s; }

.page-sections {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 4rem 0 3rem;
  scroll-behavior: smooth; }

.ps-main {
  flex-grow: 1;
  display: flex;
  align-items: center; }

@keyframes fadein {
  0% {
    opacity: 0.01; }
  100% {
    opacity: 0.99; } }

@keyframes fadeinsmoke {
  0% {
    opacity: 0.01; }
  100% {
    opacity: 0.18; } }

.page-why canvas,
.page-what canvas,
.page-when canvas {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: 100% !important;
  height: 100% !important;
  opacity: 0.01;
  animation: fadeinsmoke 2.6s forwards; }

.page-why .view-three .ps-main,
.page-what .view-three .ps-main,
.page-when .view-three .ps-main {
  position: relative;
  overflow: hidden; }
  .page-why .view-three .ps-main .inner-wrap,
  .page-what .view-three .ps-main .inner-wrap,
  .page-when .view-three .ps-main .inner-wrap {
    z-index: 1; }

.page-why > .main,
.page-what > .main,
.page-when > .main {
  position: relative;
  z-index: 1; }

.page-why .def-lead .section-header h3 {
  margin-left: -1.5rem;
  margin-right: -1.5rem; }

.svg-wrap {
  display: table;
  margin: 5vh auto; }
  .svg-wrap svg {
    height: 350px;
    max-width: 100%;
    max-height: 32vh !important; }

.view-two {
  z-index: 1; }

.view-three {
  z-index: 1;
  padding: 0;
  display: block; }
  .view-three .ps-main {
    background-repeat: no-repeat;
    background-position: top center;
    padding: 3rem 6%;
    min-height: 100%; }
  .view-three .section-header {
    margin-bottom: 0; }
  .view-three .section-heading {
    font-size: 27px; }
    .view-three .section-heading strong {
      font-size: 47px; }
  .view-three video {
    opacity: 0.5; }

.view-three-content {
  text-align: center;
  font-size: 18px; }
  .view-three-content h2 {
    color: #ff4242;
    font-size: 72px;
    margin-bottom: 0.45em;
    line-height: 1.2; }
  .view-three-content h3 {
    font-size: 35px;
    text-transform: uppercase;
    color: #ff4242;
    margin-top: -0.8em;
    line-height: 1.4;
    margin-bottom: 1em; }
  .view-three-content p {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto; }
  .view-three-content .btn {
    margin: 1.8rem 0 1rem;
    font-size: 17px;
    padding: 12px 37px;
    min-width: 199px;
    background-color: #696c6d; }

.learn-more-why {
  background: #fff;
  padding: 9rem 0;
  min-height: 100%; }

.default-page {
  font-size: 20px;
  background: #fff;
  min-height: 100%;
  padding: 3rem 0;
  background-size: 100% auto;
  background-position: top;
  background-repeat: no-repeat; }
  .default-page ul {
    padding-left: 20px; }
  .default-page li {
    line-height: 1.4;
    margin-bottom: 15px; }
  .default-page p > a {
    transition: color 0.07s; }
    .default-page p > a:hover {
      text-decoration: none; }

.def-lead {
  margin: 5rem 0; }
  .def-lead .inner-wrap {
    max-width: 885px; }
  .def-lead .section-header .section-heading {
    font-size: 27px;
    margin-bottom: 2rem; }
    .def-lead .section-header .section-heading strong {
      font-size: 47px; }
  .def-lead .section-header p {
    font-size: 20px;
    font-style: initial;
    line-height: 1.8; }
  .def-lead .section-header h3 {
    margin: 3.7rem 0; }
  .def-lead .section-header h4 {
    color: #1f93cf;
    font-size: 33px; }

.grey-black-headline,
.grey-red-headline {
  font-size: 50px;
  font-weight: 400;
  line-height: 1.2;
  color: #878787;
  margin-bottom: 2rem; }
  .grey-black-headline strong,
  .grey-red-headline strong {
    color: #434343; }

.grey-red-headline strong {
  color: #ff4242; }

.content-vid .inner-wrap {
  display: flex;
  align-items: center;
  max-width: 1170px; }

.content-vid .cv-content {
  text-align: justify; }

.content-vid .cv-vid {
  background-color: #333;
  width: 650px;
  max-width: 50%;
  margin-left: 7%;
  flex-shrink: 0; }

.graphic-section {
  margin: 3.5rem 0;
  text-align: center; }
  .graphic-section .inner-wrap {
    max-width: 1170px; }
  .graphic-section figure {
    margin: 3rem 0; }
  .graphic-section img {
    margin: 0 auto; }

.blue-two-col {
  margin: 7rem 0;
  padding: 6.5rem 0 7rem;
  background: #1f93cf;
  color: #fff;
  background-size: cover;
  background-position: center; }
  .blue-two-col h2 {
    font-size: 50px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 5rem; }
  .blue-two-col .inner-wrap {
    max-width: 1020px; }
  .blue-two-col a {
    color: #fff;
    text-decoration: underline;
    font-weight: bold; }

.two-col-content {
  display: flex;
  justify-content: space-around; }
  .two-col-content > div {
    width: 45%; }
  .two-col-content p {
    line-height: 1.55; }

.ta-center {
  text-align: center; }

.narrow-jumble .inner-wrap {
  max-width: 880px; }

.narrow-jumble h2,
.narrow-jumble h3 {
  text-align: center;
  margin: 2.8rem 0; }

.narrow-jumble h3 {
  color: #1f93cf;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.3; }

.narrow-jumble .btn {
  display: table;
  margin: 4rem auto; }

.page-when .narrow-jumble h2,
.page-when .narrow-jumble h3 {
  text-align: left; }

.page-when .vf-left .vf-vid {
  margin: 9px 3rem 1rem 0; }

.jumble-two-col {
  display: flex;
  align-items: flex-start; }

.jtc-img {
  margin-right: 8%; }

.next-link {
  background-color: #0a4b6c;
  color: #fff !important;
  text-decoration: none !important;
  padding: 0.7rem 2.1rem;
  position: absolute;
  top: 50%;
  top: calc(50% - 35px);
  right: 0;
  z-index: 1;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  transition: all 0.1s;
  opacity: 0.01;
  animation: fadein 0.7s forwards;
  animation-delay: 0.6s; }
  .next-link:hover, .next-link:focus {
    background-color: #0c587f; }
  .next-link:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 20px solid #fff;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    top: -2px;
    position: relative; }

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

@keyframes shake-horizontal {
  0%,
  100% {
    transform: translateX(0); }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-4px); }
  20%,
  40%,
  60% {
    transform: translateX(4px); }
  80% {
    transform: translateX(4px); }
  90% {
    transform: translateX(-4px); } }

.home-next {
  position: absolute;
  right: 18px;
  width: 250px;
  display: block;
  text-decoration: none !important;
  color: #fff !important; }
  .home-next svg {
    position: relative;
    display: block;
    margin: 0 auto;
    animation: shake-horizontal 3.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite both; }
  .home-next h3 {
    font-size: 19px;
    margin-top: 12px;
    line-height: 1.4;
    text-transform: uppercase;
    letter-spacing: 0.04em; }

.page-who .view-three-content h3 {
  margin-top: -0.5em; }

.page-who .view-three-content p {
  max-width: 835px; }

.page-full-form .section-header {
  margin-bottom: 4rem; }
  .page-full-form .section-header p {
    margin-top: 1.5rem; }

.page-full-form .view-one {
  display: block;
  position: relative;
  padding: 0 !important;
  overflow: visible; }
  .page-full-form .view-one .gradient-wrap {
    width: 930px; }
  .page-full-form .view-one .gw-inner {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 3% 4rem; }

.page-full-form .form-button {
  width: 100%;
  margin: 1rem 0 0; }

.form-section {
  padding: 2.5rem 3% 2rem;
  position: relative;
  width: 50%;
  flex-grow: 1; }
  .form-section:first-child, .form-section:nth-child(2) {
    padding-bottom: 0; }
  .form-section input[type='text'],
  .form-section input[type='email'] {
    margin: 9px 0 1rem;
    background: transparent;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 20px;
    padding: 5px 15px;
    width: 280px; }

.video-float {
  margin: 3.5rem 0; }
  .video-float:after {
    content: '';
    display: table;
    clear: both; }
  .video-float .inner-wrap {
    max-width: 1170px; }
  .video-float .grey-black-headline {
    margin-bottom: 4rem; }

.vf-content h3 {
  color: #1f93cf;
  font-size: 1.1em;
  margin: 1.5rem 0;
  line-height: 1.3; }

.vf-vid {
  float: right;
  margin: 9px 0 1rem 2rem;
  width: 49%;
  max-width: calc(100% - 180px); }

.vf-img {
  width: 42%;
  margin: 9px 0 1rem 5%; }

.vf-left .vf-vid {
  float: left;
  margin: 9px 2rem 1rem 0; }

.vf-left .vf-img {
  margin: 9px 5% 1rem 0; }

.btn-submit {
  position: relative;
  overflow: hidden; }

@keyframes rotatemiddle {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.button-spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 11px;
  background-color: #fff; }
  .button-spinner svg {
    height: 30px;
    animation: rotatemiddle 1.3s linear infinite; }

sup {
  font-weight: bold;
  font-size: 12px; }

.p-cite {
  position: relative; }
  .p-cite .the-citations {
    position: absolute;
    top: -2px;
    line-height: 1;
    margin-left: 2px; }
  .p-cite sup {
    margin-left: 1px; }

h3 .p-cite .the-citations,
h4 .p-cite .the-citations {
  top: -7px; }

.citations {
  background-color: #f1f1f1;
  padding: 3.5rem 0;
  font-size: 16px;
  margin-bottom: -3rem;
  margin-top: 5rem; }
  .citations .inner-wrap {
    max-width: 1020px; }
  .citations ol {
    margin: 0;
    padding-left: 15px;
    margin-top: 17px; }
  .citations li {
    margin-bottom: 9px; }

.left-justify p {
  text-align: left !important; }

.about-page .btn {
  margin: 1.5rem 0 3rem; }

.about-page .section-header {
  text-align: left; }
  .about-page .section-header ul {
    text-align: left;
    margin: 1.5rem 0; }

.nj-callout {
  padding: 2.7rem 0;
  margin: 3.3rem 0;
  position: relative; }
  .nj-callout h3 {
    margin: 0; }
  .nj-callout:before, .nj-callout:after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    height: 2px;
    background: #1f93cf;
    background: linear-gradient(to left, rgba(31, 148, 207, 0), rgba(31, 148, 207, 0.5), rgba(31, 148, 207, 0.5), rgba(31, 148, 207, 0)); }
  .nj-callout:after {
    top: auto;
    bottom: 0; }

.header-wrap-mobile {
  background: #0a4b6c;
  display: flex;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1; }

.mobile-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 75, 108, 0.96);
  background: linear-gradient(165deg, rgba(13, 93, 133, 0.97), rgba(10, 87, 126, 0.97));
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto; }
  .mobile-menu .nav-links {
    font-size: 3em; }
  .mobile-menu .nav-item {
    text-align: center;
    padding: 0.4rem 0; }
  .mobile-menu a {
    color: #fff;
    text-decoration: none !important;
    letter-spacing: 0.04em; }
    .mobile-menu a.active {
      font-weight: bold; }

.hwm-logo {
  position: relative;
  background: #fff;
  padding: 7px 15px 9px 30px; }
  .hwm-logo:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transform-origin: top right;
    transform: skew(30deg); }
  .hwm-logo img {
    width: 65px;
    position: relative; }

@media (max-width: 1920px) {
  .nav-center a {
    padding: 30px 39px; } }

@media (max-width: 1680px) {
  .page-home .inner-wrap {
    max-width: 920px; } }

@media (max-width: 1500px) {
  .page-home .inner-wrap {
    max-width: 800px; }
  .page-home h2 {
    font-size: 50px; }
    .page-home h2 strong {
      font-size: 90px; }
  .nav-center a {
    padding: 30px; }
  .navbar {
    padding: 0 50px 0 310px; }
  .corner-logo {
    height: 280px; }
    .corner-logo img {
      width: 120px;
      bottom: 17px;
      left: 127px; }
  .svg-wrap {
    margin: 2rem auto !important; }
    .svg-wrap svg {
      max-height: 23vh !important; }
  .section-heading {
    font-size: 40px; }
    .section-heading strong {
      font-size: 63px; } }

@media (max-width: 1370px) {
  .page-home {
    flex-direction: column; }
  .home-next {
    position: relative;
    right: auto;
    display: table;
    margin: 4rem auto 0; } }

@media (max-width: 1259px) {
  .page-why .def-lead .section-header h3 {
    margin-left: 0;
    margin-right: 0; }
  .droplets {
    align-self: center;
    margin-left: auto; } }

@media (max-width: 1024px) {
  .page-sections {
    display: block; }
  .page {
    padding: 5rem 0; }
  .page-home {
    display: block;
    padding: 5rem 0 3rem; }
    .page-home h2 {
      font-size: 48px; }
      .page-home h2 strong {
        margin-top: 0.7rem;
        font-size: 86px; }
  .home-embed {
    padding: 9px;
    max-width: 650px;
    display: table;
    margin: 0 auto; }
  .vid-overlay .vo-icon {
    width: 90px;
    height: 90px;
    border: 6px solid #fff; }
  .vid-overlay svg {
    left: 29px;
    top: 20px; }
  .vid-overlay p {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 20px; }
  .default-page {
    background-size: 170% auto;
    padding-bottom: 5rem; }
  .def-lead {
    margin: 3rem 0; }
    .def-lead .section-header {
      margin-bottom: 3.5rem; }
      .def-lead .section-header h3 {
        margin: 2.5rem 0; }
  .grey-black-headline,
  .grey-red-headline {
    font-size: 42px;
    margin-bottom: 1.5rem; }
  .content-vid .inner-wrap {
    flex-direction: column; }
  .content-vid .cv-vid {
    max-width: 100%;
    margin-left: 0;
    order: -1;
    width: 100%;
    margin-bottom: 2.5rem; }
  .content-vid .cv-content {
    text-align: left; }
  .grey-black-headline br {
    display: none; }
  .blue-two-col {
    margin: 5rem 0;
    padding: 5.5rem 0 5rem;
    background-position: left center; }
    .blue-two-col br {
      display: none; }
    .blue-two-col h2 {
      margin-bottom: 4rem; }
  .graphic-section {
    margin: 3rem 0; }
  .jumble-two-col {
    display: block; }
  .jtc-img {
    float: right;
    margin: 0 0 1rem 1.2rem;
    max-width: calc(100% - 180px); }
  .narrow-jumble h3 {
    font-size: 38px; }
  .page-sections:not(.view-three) {
    padding: 5rem 0; }
  .page-full-form {
    padding: 3.5rem 0 2rem; }
  .section-header {
    margin-bottom: 4.5rem; }
  .view-three .ps-main {
    padding: 4rem 6%; }
  .next-link {
    left: 0;
    bottom: 0;
    right: auto;
    top: auto;
    width: 100%;
    text-align: center;
    font-size: 25px;
    letter-spacing: 0.05em;
    padding: 15px 10px 13px;
    line-height: 1; }
    .next-link:after {
      border-top: 8.5px solid transparent;
      border-bottom: 8.5px solid transparent;
      border-left: 14px solid #fff;
      margin-left: 9px; }
  .view-three-content br {
    display: none; }
  .view-three-content h2 {
    font-size: 43px;
    margin-bottom: 1.6rem;
    line-height: 1.1; }
  .view-three-content h3 {
    font-size: 24px;
    margin-top: -0.5em;
    margin-bottom: 1rem;
    line-height: 1.2; }
  .view-three-content .btn {
    margin: 1rem 0; } }

@media (max-width: 775px) {
  .page-full-form .view-one .gradient-wrap {
    width: 100%; }
  .about-page {
    padding-bottom: 2rem; } }

@media (max-width: 760px) {
  .form-section {
    width: 100%;
    padding-bottom: 0;
    padding: 1.2rem 0; } }

@media (max-width: 600px) {
  .nj-callout {
    padding: 2rem 0;
    margin: 2.2rem 0; }
  .next-link {
    font-size: 17px; }
    .next-link:after {
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 10px solid #fff;
      margin-left: 6px; }
  .page-full-form .section-header {
    margin-bottom: 2.5rem; }
  .inner-wrap {
    width: 100%; }
  .hwm-logo {
    padding: 6px 2px 7px 15px;
    margin-right: 20px; }
    .hwm-logo:before {
      transform: skew(32deg); }
    .hwm-logo img {
      width: 50px; }
  .page-home {
    padding: 3.8rem 0 2rem; }
    .page-home h2 {
      font-size: 25px;
      margin-bottom: 2.5rem; }
      .page-home h2 strong {
        line-height: 1.05;
        font-size: 42px;
        margin-top: 7px; }
  .home-embed {
    padding: 5px; }
  .vid-overlay .vo-icon {
    width: 65px;
    height: 65px;
    border: 3px solid #fff; }
  .vid-overlay svg {
    transform: scale(0.7);
    left: 18px;
    top: 9px; }
  .vid-overlay p {
    font-size: 15px;
    margin-top: 12px; }
  .home-next {
    width: 212px;
    margin: 3rem auto 0; }
    .home-next h3 {
      font-size: 15px;
      margin-top: 17px; }
  .section-heading {
    font-size: 24px; }
    .section-heading strong {
      font-size: 33px;
      margin-top: 7px; }
  .page-sections:not(.view-three) {
    padding: 3rem 0 2.5rem; }
  .section-header {
    margin-bottom: 2.5rem; }
  .gradient-wrap {
    padding: 7px; }
  .gw-inner {
    padding: 2rem 8%; }
    .gw-inner.second h3 {
      font-size: 28px; }
    .gw-inner h3 {
      font-size: 21px;
      line-height: 1.3; }
    .gw-inner br {
      display: none; }
    .gw-inner p {
      font-size: 14px; }
    .gw-inner .answer-exp {
      margin: 1rem 0; }
      .gw-inner .answer-exp p {
        font-size: 19px; }
    .gw-inner .btn {
      margin-top: 0.7rem; }
  .section-footer {
    margin-top: 0.7rem; }
  .btn {
    min-width: 220px;
    padding: 11px 29px; }
  .button-spinner {
    padding-top: 10px; }
    .button-spinner svg {
      height: 26px; }
  .section-footer p {
    line-height: 1.3; }
  .mobile-menu {
    display: block;
    padding: 40px 0; }
    .mobile-menu .nav-item {
      padding: 1.5vw 0; }
    .mobile-menu .nav-links {
      font-size: 7.5vw; }
  .section-header p {
    font-size: 16px; }
  .view-three .ps-main {
    padding: 3rem 6% 2rem; }
  .view-three .section-heading {
    font-size: 24px; }
    .view-three .section-heading strong {
      font-size: 33px;
      margin-top: 7px; }
  .view-three-content {
    font-size: 16px; }
    .view-three-content br {
      display: none; }
    .view-three-content h2 {
      font-size: 32px;
      margin-bottom: 1rem; }
    .view-three-content h3 {
      font-size: 18px;
      margin-top: -0.2em; }
    .view-three-content .btn {
      font-size: 15px; }
  .svg-wrap {
    margin: 1.2rem auto !important; }
  .default-page {
    background-size: 1800px auto; }
  .page-full-form .view-one .gw-inner {
    padding: 1rem 6% 3rem; }
  .form-section input[type='text'],
  .form-section input[type='email'] {
    width: auto;
    padding: 4px 15px;
    font-size: 17px; }
  .default-page {
    font-size: 17px; }
  .def-lead {
    margin: 1.3rem 0; }
    .def-lead .section-header {
      margin-bottom: 2.5rem; }
      .def-lead .section-header .section-heading {
        font-size: 24px;
        margin-bottom: 2rem; }
        .def-lead .section-header .section-heading strong {
          font-size: 38px; }
      .def-lead .section-header p {
        font-size: 17px; }
      .def-lead .section-header h3 {
        margin: 1.5rem 0; }
      .def-lead .section-header h4 {
        font-size: 26px;
        line-height: 1.2; }
  .grey-black-headline,
  .grey-red-headline {
    font-size: 34px; }
  .graphic-section {
    margin: 1.5rem 0; }
  br {
    display: none; }
  .blue-two-col {
    padding: 3.5rem 0 3rem;
    margin: 2rem 0;
    background-position: right center; }
    .blue-two-col h2 {
      font-size: 37px;
      margin-bottom: 2rem; }
  .two-col-content {
    display: block; }
    .two-col-content > div {
      width: 100%; }
  .narrow-jumble h2 {
    margin: 3rem 0 2rem; }
  .narrow-jumble h3 {
    font-size: 31px; }
  .narrow-jumble .btn {
    margin: 3rem 0 0.5rem; }
  .video-float {
    margin: 3rem 0; }
    .video-float .inner-wrap {
      display: flex;
      flex-direction: column; }
    .video-float .grey-black-headline {
      margin-bottom: 1.4rem; }
    .video-float .vf-vid {
      order: 2;
      max-width: 100%;
      width: 100%;
      margin: 0 !important; } }

@media (max-width: 350px) {
  .droplets {
    transform: scale(0.8); } }

@media (min-width: 1100px) and (max-height: 1150px) {
  .nav-center a {
    padding: 26px; }
  .page-sections {
    display: block; }
  .home-embed {
    width: 800px;
    display: table;
    margin-left: auto;
    margin-right: auto;
    padding: 11px; }
  .section-header {
    margin-bottom: 3rem;
    margin: 0 auto;
    display: table; }
  .section-heading {
    max-width: 900px; }
  .view-three-content {
    font-size: 17px; }
  .view-three-content h2 {
    font-size: 50px; }
  .view-three-content h3 {
    font-size: 28px; }
  .view-three-content .btn {
    margin: 1rem 0 1rem; }
  .svg-wrap {
    margin: 1.5rem auto !important; }
    .svg-wrap svg {
      max-height: 29vh !important; }
  .default-page {
    font-size: 17px;
    padding: 2rem 0; }
  .page-home h2 {
    font-size: 50px;
    margin-bottom: 1em; }
    .page-home h2 strong {
      font-size: 87px; }
  .home-next {
    margin: 3rem auto 0; } }

@media (width: 1440px) and (max-height: 900px) {
  .view-three-content h2 {
    font-size: 43px; }
  .view-three-content h3 {
    font-size: 23px; }
  .view-three-content {
    font-size: 16px; }
  .svg-wrap svg {
    max-height: 23vh !important; }
  .view-three-content .btn {
    font-size: 15px;
    padding: 10px;
    min-width: 175px; }
  .next-link {
    font-size: 26px; } }
